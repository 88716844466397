import React from 'react';
import RouteWithLayout from './components/RouteWithLayout';
import { Main as MainLayout, Minimal as MinimalLayout } from './components/layouts';
import {
  Dashboard as DashboardView,
  Login as LoginView,
  Usuarios as UsuariosView,
  UsuarioDocumentoCertificado as UsuarioDocumentoCertificadosView,
  Malhas as MalhasView,
  Gerencias as GerenciasView,
  Polos as PolosView,
  Subestacoes as SubestacoesView,
  Alimentadores as AlimentadoresView,
  Nsirs as NsirsView,
  Nsir as NsirView,
  Convocacao as ConvocacaoView,
  MeuPerfil as MeuPerfilView,
  Documentos as DocumentosView,
  Odometros as OdometrosView,
  Veiculos as VeiculosView,
  Notas as NotasView,
  Cartoes as CartoesView,
  Aportes as AportesView,
  AporteUsuario as AporteUsuarioView,
  ControleCaixa as ControleCaixaView,
  Drones as DroneView,
  Equipamentos as EquipamentoView,
  ControlePonto as ControlePontoView,
  Estoque as EstoqueView,
  Produtos as ProdutosView,
  Pedidos as PedidosView,
  InspecaoVeicular as InspecaoVeicularView,
  ControleFrequencia as ControleFrequenciaView,
} from '../views';

const Routes = () => (
  <>
    <RouteWithLayout
      component={LoginView}
      exact
      layout={MinimalLayout}
      path="/"
      isPublic
    />
    <RouteWithLayout
      component={LoginView}
      exact
      layout={MinimalLayout}
      path="/login"
      isPublic
    />
    <RouteWithLayout
      component={DashboardView}
      exact
      layout={MainLayout}
      path="/dashboard"
    />
    <RouteWithLayout
      component={NsirsView}
      exact
      layout={MainLayout}
      path="/nsirs"
    />
    <RouteWithLayout
      component={NsirView}
      exact
      layout={MainLayout}
      path="/nsir"
    />
    <RouteWithLayout
      component={NsirView}
      exact
      layout={MainLayout}
      path="/nsir/:nsirId"
    />
    <RouteWithLayout
      component={ConvocacaoView}
      exact
      layout={MainLayout}
      path="/convocacoes"
    />
    <RouteWithLayout
      component={DroneView}
      exact
      layout={MainLayout}
      path="/drones"
    />
    <RouteWithLayout
      component={EquipamentoView}
      exact
      layout={MainLayout}
      path="/equipamentos"
    />
    <RouteWithLayout
      component={OdometrosView}
      exact
      layout={MainLayout}
      path="/odometros"
    />
    <RouteWithLayout
      component={NotasView}
      exact
      layout={MainLayout}
      path="/notas"
    />
    <RouteWithLayout
      component={AportesView}
      exact
      layout={MainLayout}
      path="/aportes"
    />
    <RouteWithLayout
      component={AporteUsuarioView}
      exact
      layout={MainLayout}
      path="/aporte/:usuarioId"
    />
    <RouteWithLayout
      component={VeiculosView}
      exact
      layout={MainLayout}
      path="/veiculos"
    />
    <RouteWithLayout
      component={InspecaoVeicularView}
      exact
      layout={MainLayout}
      path="/inspecoes-veiculares"
    />
    <RouteWithLayout
      component={CartoesView}
      exact
      layout={MainLayout}
      path="/cartoes"
    />
    <RouteWithLayout
      component={ControleCaixaView}
      exact
      layout={MainLayout}
      path="/controle-caixa"
    />
    <RouteWithLayout
      component={UsuariosView}
      exact
      layout={MainLayout}
      path="/usuarios"
    />
    <RouteWithLayout
      component={UsuarioDocumentoCertificadosView}
      exact
      layout={MainLayout}
      path="/usuarios-documentos"
    />
    <RouteWithLayout
      component={ControlePontoView}
      exact
      layout={MainLayout}
      path="/controle-ponto"
    />
    <RouteWithLayout
      component={MalhasView}
      exact
      layout={MainLayout}
      path="/malhas"
    />
    <RouteWithLayout
      component={GerenciasView}
      exact
      layout={MainLayout}
      path="/gerencias"
    />
    <RouteWithLayout
      component={PolosView}
      exact
      layout={MainLayout}
      path="/polos"
    />
    <RouteWithLayout
      component={SubestacoesView}
      exact
      layout={MainLayout}
      path="/subestacoes"
    />
    <RouteWithLayout
      component={AlimentadoresView}
      exact
      layout={MainLayout}
      path="/alimentadores"
    />
    <RouteWithLayout
      component={MeuPerfilView}
      exact
      layout={MainLayout}
      path="/meu-perfil"
    />
    <RouteWithLayout
      component={DocumentosView}
      exact
      layout={MainLayout}
      path="/documentos"
    />
    <RouteWithLayout
      component={EstoqueView}
      exact
      layout={MainLayout}
      path="/estoques"
    />
    <RouteWithLayout
      component={ProdutosView}
      exact
      layout={MainLayout}
      path="/estoque/produtos"
    />
    <RouteWithLayout
      component={PedidosView}
      exact
      layout={MainLayout}
      path="/estoque/pedidos"
    />
    <RouteWithLayout
      component={ControleFrequenciaView}
      exact
      layout={MainLayout}
      path="/controle-frequencia"
    />
  </>
);

export default Routes;
