import * as Type from './actionsTypes';

const loadControleFrequencia = id => ({ type: Type.FETCH_CONTROLE_FREQUENCIA, id });

const loadControleFrequencias = filtro => ({ type: Type.FETCH_CONTROLE_FREQUENCIAS, filtro });

const cleanSelectedControleFrequencia = () => ({ type: Type.CLEAR_SELECTED_CONTROLE_FREQUENCIA });

const saveControleFrequencia = (data, callback) => ({ type: Type.SAVE_CONTROLE_FREQUENCIA, data, callback });

const exportControleFrequencia = (filtro, callback) => ({ type: Type.EXPORT_CONTROLE_FREQUENCIAS, filtro, callback });

const reportControleFrequencia = (filtro, callback) => ({ type: Type.REPORT_CONTROLE_FREQUENCIAS, filtro, callback });

const deleteControleFrequencia = (id, callback) => ({ type: Type.CONTROLE_FREQUENCIA_DELETE, id, callback });

export {
  loadControleFrequencia,
  loadControleFrequencias,
  cleanSelectedControleFrequencia,
  saveControleFrequencia,
  exportControleFrequencia,
  reportControleFrequencia,
  deleteControleFrequencia
}
