/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { TableCell, Grid, DialogContentText } from '@material-ui/core';
import { Table, DefaultFiltro, DialogDefault } from '../../components';
import * as selectors from '../../store/controleFrequencia/reducer';
import * as actions from '../../store/controleFrequencia/actions';
import Formulario from './Formulario';

import * as Roles from '../../constants/role';
import * as selectorsAuth from '../../store/auth/reducer';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
}));

const ControleFrequencia = ({ controleFrequenciais, loadControleFrequencias, authData, exportControleFrequencia, deleteControleFrequencia }) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [titles] = useState(['Data', 'Tipo de Serviço', 'Cidade', 'Inspetor e Auxiliar', 'Total da Jornada']);
  const classes = useStyles();
  const [localFilter, setLocalFilter] = useState({
      inicio: moment().subtract(1, 'month').startOf('month').valueOf(),
      fim: moment().endOf('month').valueOf()
    });
  const [openExport, setOpenExport] = useState(false);

  const renderRows = r => {
    return (
      <>
        <TableCell size="small"> {moment(parseInt(r.data)).format('DD/MM/YYYY')} </TableCell>
        <TableCell size="small">{r.tipoServicoNome}</TableCell>
        <TableCell size="small">{r.cidade}</TableCell>
        <TableCell size="small">
          <span style={{ marginRight: '5px' }}>
            {r.inspetorNome}
          </span> <br/> 
          <span style={{ marginRight: '5px' }}>
            {r.inspetorAuxiliarNome}
          </span>
        </TableCell>
        <TableCell>{r.jornadaTotal}</TableCell>
      </>
    )
  }

  useEffect(() => {
    loadControleFrequencias(localFilter)
  }, [loadControleFrequencias]);

  const onEdit = id => {
    setOpen(true);
    setId(id);
  }

  const onClose = isSave => {
    setOpen(false);
    setId(null);

    if (isSave) {
      loadControleFrequencias(localFilter);
    }
  }

  const isAdmin = () => authData && (authData.role === Roles.ADMIN || authData.role === Roles.SUPER_ADMIN);

  const getHeaderActions = () => {
    return [
      {
        title: "Exportar",
        onClick: () => setOpenExport(true)
      }
    ]
  }

  const onAdd = () => {
    setOpen(true);
    setId(null);
  }

  const onDelete = (id, callback) => deleteControleFrequencia(id, callback);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <DefaultFiltro
            useDates
            getDefaultDates={() => ({
              inicio: moment().subtract(1, 'month').startOf('month').valueOf(),
              fim: moment().endOf('month').valueOf()
            })}
            useInspetor
            useInspetorMultiSelect
            useTipoServico
            mdInspetor={4}
            labelInspetor="Inspetor e Auxiliar"
            useCodigo
            mdCodigo={4}
            codigoLabel="Cidade"
            onFilter={filterData => {
              setLocalFilter(filterData);
              loadControleFrequencias(filterData);
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Table
          rows={controleFrequenciais}
          titles={titles}
          headerTitle="Controle de Frequências"
          renderRows={renderRows}
          onAdd={onAdd}
          onEdit={onEdit}
          hasRowActions={isAdmin()}
          showDelete={isAdmin()}
          onDelete={onDelete}
          headerActions={getHeaderActions()}
        />
        <Formulario
          id={id}
          open={open}
          onClose={onClose}
        />
      </Grid>
      <DialogDefault
        open={openExport}
        handlerCancel={() => setOpenExport(false)}
        handlerConfirm={() => exportControleFrequencia(localFilter, () => setOpenExport(false))}
        title="Confirmação"
        confirmMessage="Exportar"
      >
        <DialogContentText> Deseja exportar todas as informações filtradas? </DialogContentText>
      </DialogDefault>
    </>
  );
};

const mapStateToProps = state => ({
  controleFrequenciais: selectors.getControleFrequenciais(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ControleFrequencia);