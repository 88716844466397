import * as Type from './actionsTypes';

const INITIAL_STATE = {
  controleFrequenciais: [],
  selectedControleFrequencia: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Type.FETCH_CONTROLE_FREQUENCIAS_SUCCESS: {
      return {
        ...state,
        controleFrequenciais: action.data
      }
    }
    case Type.FETCH_CONTROLE_FREQUENCIA_SUCCESS: {
      return {
        ...state,
        selectedControleFrequencia: action.data
      }
    }
    case Type.CONTROLE_FREQUENCIA_DELETE_SUCCESS: {
      return {
        ...state,
        controleFrequenciais: state.controleFrequenciais.filter(a => a.id !== action.id)
      };
    }
    case Type.CLEAR_SELECTED_CONTROLE_FREQUENCIA: {
      return {
        ...state,
        selectedControleFrequencia: {}
      }
    }
    case Type.CLEAR_CONTROLE_FREQUENCIA: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}

export const getControleFrequenciais = state => state.rootState.controleFrequencia.controleFrequenciais;
export const getSelectedControleFrequencia = state => state.rootState.controleFrequencia.selectedControleFrequencia;