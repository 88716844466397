/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import validate from 'validate.js';

import * as selectorsControleFrequencias from '../../store/controleFrequencia/reducer';
import * as actionsControleFrequencias from '../../store/controleFrequencia/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import * as Role from '../../constants/role';
import moment from 'moment';

import { DialogDefault, Input, InputFormat, UsuarioSelect, OptionSelect, DatePicker, Dropzone } from '../../components';
import { convertTimeToMinutes, isNullOrUndefined, isValidTime, formatToTime } from 'utils';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  buttonContainer: {
    width: '100%'
  }
}));

const defaultSchema = {
  data: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  tipoServico: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  cidade: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  inspetor: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  inicioJornada: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  fotoInicioJornada: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const validateInvalidTimes = values => {
  let errors = {};

  if (!isNullOrUndefined(values.inicioJornada) && !isValidTime(values.inicioJornada)) {
    errors = {
      ...errors,
      inicioJornada: ['Horário inválido'],
    }
  }

  if (!isNullOrUndefined(values.inicioIntervalo) && !isValidTime(values.inicioIntervalo)) {
    errors = {
      ...errors,
      inicioIntervalo: ['Horário inválido'],
    }
  }

  if (!isNullOrUndefined(values.finalIntervalo) && !isValidTime(values.finalIntervalo)) {
    errors = {
      ...errors,
      finalIntervalo: ['Horário inválido'],
    }
  }

  if (!isNullOrUndefined(values.finalJornada) && !isValidTime(values.finalJornada)) {
    errors = {
      ...errors,
      finalJornada: ['Horário inválido'],
    }
  }

  return errors;
}

const hasError = values => {
  let schema = defaultSchema;

  if ((values.inicioIntervalo != null && values.fotoInicioIntervalo == null) || (values.inicioIntervalo == null && values.fotoInicioIntervalo != null)) {
    schema = {
      ...schema,
      inicioIntervalo: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      fotoInicioIntervalo: { presence: { allowEmpty: false, message: 'é obrigatório' } },
    }
  }

  if ((values.finalIntervalo != null && values.fotoFinalIntervalo == null) || (values.finalIntervalo == null && values.fotoFinalIntervalo != null)) {
    schema = {
      ...schema,
      finalIntervalo: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      fotoFinalIntervalo: { presence: { allowEmpty: false, message: 'é obrigatório' } },
    }
  } 
  
  if ((values.finalJornada != null && values.fotoFinalJornada == null) || (values.finalJornada == null && values.fotoFinalJornada != null)) {
    schema = {
      ...schema,
      finalJornada: { presence: { allowEmpty: false, message: 'é obrigatório' } },
      fotoFinalJornada: { presence: { allowEmpty: false, message: 'é obrigatório' } },
    }
  }

  return {
    ...validate(values, schema),
    ...validateInvalidTimes(values),
  }
}

const Formulario = ({ open, id, onClose, loadControleFrequencia, controleFrequencia, saveControleFrequencia, authData }) => {
  const classes = useStyles();

  const [readOnly, setReadOnly] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    data: moment().valueOf()
  });

  useEffect(() => {
    if (id) loadControleFrequencia(id);
  }, [id, loadControleFrequencia]);

  useEffect(() => {
    if (id && controleFrequencia && id == controleFrequencia.id) {
      setValues({
        ...controleFrequencia,
        data: moment(parseInt(controleFrequencia.data)).valueOf()
      });

      setReadOnly(authData.id != controleFrequencia.criadoPor)
    } else {
      setValues({ data: moment().valueOf() });
      setReadOnly(false);
    }
  }, [controleFrequencia, id]);

  const onSave = () => {
    const erros = hasError(values);
    if (erros != null && Object.keys(erros).length > 0) {
      setErrors(erros);
      return;
    }

    saveControleFrequencia(values, () => onCloseLocal(true));
  }

  const onCloseLocal = isSave => {
    setErrors({});
    setValues({ data: moment().valueOf() });
    setReadOnly(false);
    onClose(isSave);
  }

  const onChange = async (e, field) => {
    const value = e.target.value;

    const inicioJornadaMinutes = convertTimeToMinutes(field == "inicioJornada"? value : values.inicioJornada);
    const inicioIntervaloMinutes = convertTimeToMinutes(field == "inicioIntervalo"? value : values.inicioIntervalo);
    const finalIntervaloMinutes = convertTimeToMinutes(field == "finalIntervalo"? value : values.finalIntervalo);
    const finalJornadaMinutes = convertTimeToMinutes(field == "finalJornada"? value : values.finalJornada);

    const first = (inicioJornadaMinutes && inicioIntervaloMinutes && inicioIntervaloMinutes > inicioJornadaMinutes) ? inicioIntervaloMinutes - inicioJornadaMinutes : null;
    const second = (finalJornadaMinutes && finalIntervaloMinutes && finalJornadaMinutes > finalIntervaloMinutes) ? finalJornadaMinutes - finalIntervaloMinutes : null;
    const jornadaTotal = first !== null || second !== null ? (first || 0) + (second || 0) : null;

    setValues({
      ...values,
      [field]: value,
      jornadaTotal: jornadaTotal ? formatToTime(jornadaTotal) : null,
    });
  }

  return (
    <>
      <DialogDefault
        open={open}
        handlerCancel={() => onCloseLocal(false)}
        handlerConfirm={onSave}
        title="Controle de Frêquencia"
        confirmMessage="Salvar"
        contentClassName={classes.contentClassName}
        maxWidth="md"
        readOnly={readOnly}
      >
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={4} className={classes.fieldContainer}>
            <DatePicker
              label={'Data'}
              onChange={data => setValues({
                ...values,
                data: data
              })}
              value={values.data}
              isRequired
              error={errors && errors.data}
              showInput
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4} className={classes.fieldContainer}>
            <OptionSelect
              label="Tipo de Serviços"
              codigo="TIPO_SERVICO"
              isRequired
              error={errors && errors.tipoServico}
              onSelect={tipoServico => setValues({
                ...values,
                tipoServico
              })}
              value={values.tipoServico}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              error={errors && errors.cidade}
              isRequired
              label="Cidade"
              name="cidade"
              onChange={event => setValues({
                ...values,
                cidade: event.target.value || null,
              })}
              value={values.cidade}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <UsuarioSelect
              label="Inspetor"
              onSelect={inspetor => setValues({
                ...values,
                inspetor
              })}
              isRequired
              error={errors && errors.inspetor}
              grupos={[Role.ADMIN, Role.INSPETOR]}
              value={values.inspetor}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <UsuarioSelect
              label="Parceiro"
              onSelect={inspetorAuxiliar => setValues({
                ...values,
                inspetorAuxiliar
              })}
              grupos={[Role.ADMIN, Role.INSPETOR]}
              value={values.inspetorAuxiliar}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4} className={classes.fieldContainer}>
            <Input label="Hora Total Trabalhada" disabled value={values.jornadaTotal} />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <InputFormat
              label="Inicio Jornada"
              value={values.inicioJornada}
              onChange={e => onChange(e, "inicioJornada")}
              format="##:##"
              placeholder="HH:MM"
              disabled={readOnly}
              isRequired
              error={errors && errors.inicioJornada}
              helperText={errors && errors.inicioJornada ? errors.inicioJornada : null}
            />
            <Dropzone 
              buttonText="Foto"
              filesLimit={1}
              acceptedFiles={['.png', '.pdf', '.jpeg', '.jpg']}
              buttonClassName={classes.buttonContainer}
              error={errors && errors.fotoInicioJornada}
              onSave={files => {
                if (files != null && files.length > 0) {
                  setValues({
                    ...values,
                    fotoInicioJornada: files[0]
                  });
                }
              }}
              disabled={readOnly}
              showGridFiles
              files={values.fotoInicioJornada != null ? [{
                file: values.fotoInicioJornada,
                nome: values.fotoInicioJornada.name,
                onDelete: () => {
                  setValues({
                    ...values,
                    fotoInicioJornada: null,
                  });
                }
              }] : []}
              canDeleteFiles={!readOnly}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <InputFormat
              label="Inicio Intervalo"
              value={values.inicioIntervalo}
              onChange={e => onChange(e, "inicioIntervalo")}
              format="##:##"
              placeholder="HH:MM"
              disabled={readOnly}
              error={errors && errors.inicioIntervalo}
              helperText={errors && errors.inicioIntervalo ? errors.inicioIntervalo : null}
            />
            <Dropzone 
              buttonText="Foto"
              filesLimit={1}
              acceptedFiles={['.png', '.pdf', '.jpeg', '.jpg']}
              buttonClassName={classes.buttonContainer}
              error={errors && errors.fotoInicioIntervalo}
              onSave={files => {
                if (files != null && files.length > 0) {
                  setValues({
                    ...values,
                    fotoInicioIntervalo: files[0],
                  });
                }
              }}
              disabled={readOnly}
              showGridFiles
              files={values.fotoInicioIntervalo != null ? [{
                file: values.fotoInicioIntervalo,
                nome: values.fotoInicioIntervalo.name,
                onDelete: () => {
                  setValues({
                    ...values,
                    fotoInicioIntervalo: null,
                  });
                }
              }] : []}
              canDeleteFiles={!readOnly}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <InputFormat
              label="Final Intervalo"
              value={values.finalIntervalo}
              onChange={e => onChange(e, "finalIntervalo")}
              format="##:##"
              placeholder="HH:MM"
              disabled={readOnly}
              error={errors && errors.finalIntervalo}
              helperText={errors && errors.finalIntervalo ? errors.finalIntervalo : null}
            />
            <Dropzone 
              buttonText="Foto"
              filesLimit={1}
              acceptedFiles={['.png', '.pdf', '.jpeg', '.jpg']}
              buttonClassName={classes.buttonContainer}
              error={errors && errors.fotoFinalIntervalo}
              onSave={files => {
                if (files != null && files.length > 0) {
                  setValues({
                    ...values,
                    fotoFinalIntervalo: files[0],
                  });
                }
              }}
              disabled={readOnly}
              showGridFiles
              files={values.fotoFinalIntervalo != null ? [{
                file: values.fotoFinalIntervalo,
                nome: values.fotoFinalIntervalo.name,
                onDelete: () => {
                  setValues({
                    ...values,
                    fotoFinalIntervalo: null,
                  });
                }
              }] : []}
              canDeleteFiles={!readOnly}
            />
          </Grid>
          <Grid item xs={3} className={classes.fieldContainer}>
            <InputFormat
              label="Final Jornada"
              value={values.finalJornada}
              onChange={e => onChange(e, "finalJornada")}
              format="##:##"
              placeholder="HH:MM"
              disabled={readOnly}
              error={errors && errors.finalJornada}
              helperText={errors && errors.finalJornada ? errors.finalJornada : null}
            />
            <Dropzone 
              buttonText="Foto"
              filesLimit={1}
              acceptedFiles={['.png', '.pdf', '.jpeg', '.jpg']}
              buttonClassName={classes.buttonContainer}
              error={errors && errors.fotoFinalJornada}
              onSave={files => {
                if (files != null && files.length > 0) {
                  setValues({
                    ...values,
                    fotoFinalJornada: files[0],
                  });
                }
              }}
              disabled={readOnly}
              showGridFiles
              files={values.fotoFinalJornada != null ? [{
                file: values.fotoFinalJornada,
                nome: values.fotoFinalJornada.name,
                onDelete: () => {
                  setValues({
                    ...values,
                    fotoFinalJornada: null,
                  });
                }
              }] : []}
              canDeleteFiles={!readOnly}
            />
          </Grid>

        </Grid>
      </DialogDefault>
    </>
  );
};

const mapStateToProps = state => ({
  controleFrequencia: selectorsControleFrequencias.getSelectedControleFrequencia(state),
  authData: selectorsAuth.getAuth(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsControleFrequencias
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
