import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from 'react-select';
import InputGroup from '../InputGroup';
import clsx from 'clsx';
import BaseService from '../../services/BaseService';

const customStyles = {
  control: base => ({
    ...base,
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  container: provided => ({
    ...provided,
    marginTop: 8
  }),
  menu: provided => ({
    ...provided,
    marginTop: 1,
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  })
}

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  error: {
    color: '#e53935',
    margin: '8px 12px 0',
    fontSize: 11,
    minHeight: '1em',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: 0.33
  },
  option: {
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    color: "inherit",
    cursor: "default",
    display: "block",
    fontSize: "inherit",
    label: "option",
    padding: "8px 12px",
    userSelect: "none",
    width: "100%",
  },
  optionHover: {
    backgroundColor: "#DEEBFF"
  },
  optionSelected: {
    backgroundColor: "#2684FF"
  }
}));

const UsuarioSelect = ({ onSelect, value, isRequired, error, grupos, label, disabled, ativo, multiSelect }) => {
  const classes = useStyles();

  const [usuarios, setUsuarios] = useState([]);

  const getUsuarios = async () => {
    let url = 'usuarios/roles';
    if (grupos) {
      url = `${url}?roles=${grupos}`;
    }

    if (ativo && url.indexOf('?') > 0) {
      url = `${url}&ativo=${ativo}`;
    } else if (ativo) {
      url = `${url}?ativo=${ativo}`;
    }

    try {
      const response = await BaseService.get({ url });
      setUsuarios(response && response.data ? response.data : []);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getUsuarios();
  }, []);

  const onChangeLocal = data => {
    if (multiSelect) {
      onSelect(data ? data.map(u => u.value) : []);
    } else {
      onSelect(data.value);
    }
  }

  // eslint-disable-next-line react/no-multi-comp
  const DropdownIndicator = () => (<ArrowDropDownIcon className={classes.arrowIcon}/>)
  const IndicatorSeparator = () => '';

  const getDefaultValue = () => {
    if (multiSelect && value && usuarios) {
      const selectedUsuarios = usuarios.filter(i => value.includes(i.id));
      return selectedUsuarios.map(u => ({value: u.id, label: u.nome}));
    }

    if (value && usuarios) {
      const usuariosSelected = usuarios.filter(i => i.id === value);
      if (usuariosSelected && usuariosSelected.length > 0) {
        return {value: usuariosSelected[0].id, label: usuariosSelected[0].nome}
      }
    }

    return null;
  }

  const Option = ({ children, innerRef,  isFocused, isSelected, innerProps, data }) => {
    return (
      <div
        ref={innerRef}
        className={clsx(classes.option, isFocused && classes.optionHover, isSelected && classes.optionSelected)}
        {...innerProps}
      >
        <div style={{ color: "black", fontSize: 14 }}>
          {children} - {data.sublabel}
        </div>
        <div>
          <span style={{fontWeight: "bold"}}>Status: </span>{data.ativo == true ? "Ativo" : "Inativo"}
        </div>
      </div>
    )
  }

  return (
    <InputGroup
      label={label}
      isRequired={isRequired}
    >
      <Select
        components={{ DropdownIndicator, IndicatorSeparator, Option }}
        value={getDefaultValue()}
        styles={customStyles}
        options={usuarios ? usuarios.map(i => ({ value: i.id, label: i.nome, sublabel: i.matricula, ativo: i.ativo })) : []}
        onChange={onChangeLocal}
        placeholder="Selecione..."
        noOptionsMessage={() => 'Sem opções'}
        isMulti={multiSelect}
        isDisabled={disabled}
        filterOption={(candidate, input) => {
          return candidate.data.__isNew__ || candidate.label.toUpperCase().includes(input.toUpperCase()) 
            || (candidate.data && candidate.data.sublabel && candidate.data.sublabel.toUpperCase().includes(input.toUpperCase()));
        }}
        menuPlacement="auto"
      />
      {error && <p class="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense Mui-error Mui-required" className={classes.error}>O campo é obrigatório</p>}
    </InputGroup>
  );
}

UsuarioSelect.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  usuarios: PropTypes.array.isRequired,
  isRequired: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  grupos: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  ativo: PropTypes.bool,
  multiSelect: PropTypes.bool,
};

UsuarioSelect.defaultProps = {
  error: false,
  isRequired: false,
  grupos: null,
  label: 'Usuário',
  disabled: false,
  ativo: null,
  multiSelect: false
};

export default UsuarioSelect;