/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import * as selectorsEquipamentos from '../../store/equipamento/reducer';
import * as actionsEquipamentos from '../../store/equipamento/actions';
import * as selectorsAuth from '../../store/auth/reducer';
import moment from 'moment';

import { Input, DialogDefault, ArquivoContainer, WidgetTab, TipoEquipamentoSelect, TrueFalseSelect, DatePicker } from '../../components';
import HistoricoTab from './HistoricoTab';

const schema = {
  tipoEquipamento: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  modelo: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  numSerie: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const Formulario = ({ saveEquipamento, updateEquipamento, selectedEquipamento, open, id, onClose, findEquipamentoById, authData }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setValues({
        criadoPor: authData.id,
        criadoPorName: authData.nome,
        dataInclusao: moment().format("DD/MM/YYYY HH:mm:ss"),
        ativo: true,
      });
    } else {
      setValues({});
    }
  }, [authData, open]);

  useEffect(() => {
    if (id) findEquipamentoById(id);
  }, [id, findEquipamentoById]);

  useEffect(() => {
    if (id && selectedEquipamento && id === selectedEquipamento.id) {
      setValues(selectedEquipamento);
    }
  }, [id, selectedEquipamento]);

  const onSave = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      if (values.id) updateEquipamento(values, saveCallback);
      else saveEquipamento(values, saveCallback);
    }
  }

  const saveCallback = success => {
    if (success) {
      setValues({});
      onCloseLocal(true);
    }
  }

  const onCloseLocal = isSave => {
    setErrors({});
    onClose(isSave);
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={() => onCloseLocal(false)}
      handlerConfirm={onSave}
      title="Equipamento"
      confirmMessage="Salvar"
      contentClassName={classes.contentClassName}
      maxWidth="md"
    >
      <WidgetTab
        widgets={[
          {
            element: <Grid container spacing={3}>
              <Grid item xs={3} className={classes.fieldContainer}>
                <TipoEquipamentoSelect
                  showEmpty
                  isRequired
                  onChange={tipoEquipamento => setValues({
                    ...values,
                    tipoEquipamento,
                  })}
                  value={values.tipoEquipamento}
                  error={errors && errors.tipoEquipamento ? true : false}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <TrueFalseSelect
                  error={errors && errors.ativo ? true : false}
                  isRequired
                  label="Ativo"
                  name="ativo"
                  onChange={handleChange}
                  value={values.ativo}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Input
                  label="Num. Patrimônio"
                  name="numPatrimonio"
                  onChange={handleChange}
                  value={values.numPatrimonio}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Input
                  error={errors && errors.modelo}
                  isRequired
                  label="Modelo"
                  name="modelo"
                  onChange={handleChange}
                  value={values.modelo}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Input
                  error={errors && errors.numSerie}
                  isRequired
                  label="Num. Serie"
                  name="numSerie"
                  onChange={handleChange}
                  value={values.numSerie}
                />
              </Grid>
              {values.tipoEquipamento === 'DRONE' && <>
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Input
                    label="Num. Serie Camera"
                    name="numSerieCamera"
                    onChange={handleChange}
                    value={values.numSerieCamera}
                  />
                </Grid>
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Input
                    label="Código SISANT"
                    name="codigoSisant"
                    onChange={handleChange}
                    value={values.codigoSisant}
                  />
                </Grid>
                <Grid item xs={3} className={classes.fieldContainer}>
                  <DatePicker
                    label="Vencimento SISANT"
                    onChange={data => setValues({ ...values, validoAteSisant: data })}
                    value={values.validoAteSisant}
                    showInput
                  />
                </Grid>
              </>}
              {['CELULAR', 'DRONE', 'RADIO'].includes(values.tipoEquipamento) &&
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Input
                    label="Código ANATEL"
                    name="codigoAnatel"
                    onChange={handleChange}
                    value={values.codigoAnatel}
                  />
                </Grid>
              }
              {values.tipoEquipamento === 'DRONE' && <Grid item xs={8} className={classes.fieldContainer}>
                <Input
                  label="Apólice de seguro"
                  name="numApoliceSeguro"
                  onChange={handleChange}
                  value={values.numApoliceSeguro}
                />
              </Grid>}
              {values.id && <>
                <Grid item xs={6} className={classes.fieldContainer}>
                  <Input
                    label="Último Repasse/Colaborador"
                    disabled
                    value={values.ultimoRepasse}
                  />
                </Grid>
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Input
                    label="Criado Por"
                    disabled
                    value={values.criadoPorName}
                  />
                </Grid>
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Input
                    label="Data Inclusão"
                    disabled
                    value={values.dataInclusao}
                  />
                </Grid>
              </>}
              <Grid item xs={12} className={classes.fieldContainer}>
                <Input
                  label="Outras informações"
                  name="informacao"
                  onChange={handleChange}
                  value={values.informacao}
                  rows={3}
                  multiline
                />
              </Grid>
              <ArquivoContainer 
                arquivos={values.arquivos}
                onSave={arquivos => setValues({ ...values, arquivos })}
              />
            </Grid>,
            label: "Informações Básicas",
            disabled: false
          },
          {
            element: <HistoricoTab idEquipamento={id} historico={values.historico? values.historico : []} />,
            label: "Histórico",
            disabled: !id
          },
        ]}
      />
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  selectedEquipamento: selectorsEquipamentos.getSelectedEquipamento(state),
  authData: selectorsAuth.getAuth(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsEquipamentos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Formulario));
