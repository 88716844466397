export const FETCH_CONTROLE_FREQUENCIA = 'FETCH_CONTROLE_FREQUENCIA';
export const FETCH_CONTROLE_FREQUENCIA_SUCCESS = 'FETCH_CONTROLE_FREQUENCIA_SUCCESS';

export const FETCH_CONTROLE_FREQUENCIAS = 'FETCH_CONTROLE_FREQUENCIAS';
export const FETCH_CONTROLE_FREQUENCIAS_SUCCESS = 'FETCH_CONTROLE_FREQUENCIAS_SUCCESS';

export const SAVE_CONTROLE_FREQUENCIA = 'SAVE_CONTROLE_FREQUENCIA';

export const CLEAR_SELECTED_CONTROLE_FREQUENCIA = 'CLEAR_SELECTED_CONTROLE_FREQUENCIA';
export const CLEAR_CONTROLE_FREQUENCIA = 'CLEAR_CONTROLE_FREQUENCIA';

export const EXPORT_CONTROLE_FREQUENCIAS = 'EXPORT_CONTROLE_FREQUENCIAS';
export const REPORT_CONTROLE_FREQUENCIAS = 'REPORT_CONTROLE_FREQUENCIAS';

export const CONTROLE_FREQUENCIA_DELETE = 'CONTROLE_FREQUENCIA_DELETE';
export const CONTROLE_FREQUENCIA_DELETE_SUCCESS = 'CONTROLE_FREQUENCIA_DELETE_SUCCESS';