/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table } from '../../components';
import { TableCell } from '@material-ui/core';
import DocumentoForm from './DocumentoForm';
import moment from 'moment';

const ArquivosTab = ({ values, setValues, readOnly }) => {
  const [open, setOpen] = useState(false);
  const [usuarioDocumento, setUsuarioDocumento] = useState(null);
  const [isCertificado, setIsCertificado] = useState(false);

  const renderRows = s => {
    return (
      <>
        <TableCell>{s.nome}</TableCell>
        <TableCell>{moment(s.dataDocumento).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{s.validoAte ? moment(s.validoAte).format("DD/MM/YYYY") : '-'}</TableCell>
      </>
    )
  }

  const getHeaderActions = isCertificado => {
    if (readOnly) {
      return [];
    }

    return [{
      title: 'Adicionar',
      onClick: () => {
        setIsCertificado(isCertificado);
        setOpen(true);
      }
    }];
  }

  const onSaveLocal = documento => {
    const existedDocumentos = values.documentos || [];

    setValues({
      ...values,
      documentos: [
        ...existedDocumentos.filter(d => d.id !== documento.id),
        documento
      ].sort((a, b) => a.nome.localeCompare(b.nome))
    });
    setUsuarioDocumento(null);
  }

  const onEdit = id => {
    var usuarioDocumento = values.documentos.filter(d => d.id === id)[0];
    setUsuarioDocumento(usuarioDocumento);
    setIsCertificado(usuarioDocumento.tipo == "CERTIFICADO");
    setOpen(true);
  }

  const onDelete = id => {
    setValues({
      ...values,
      documentos: values.documentos.filter(d => d.id !== id)
    })
  }

  const getRows = tipo => {
    const existedDocumentos = values.documentos || [];
    return existedDocumentos.filter(d => d.tipo === tipo);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Table
          rows={getRows("DOCUMENTO")}
          titles={['Nome', 'Data Documento', 'Válido Até']}
          headerTitle="Documentos"
          hasAdd={false}
          renderRows={renderRows}
          headerActions={getHeaderActions(false)}
          hasRowActions
          showEdit={!readOnly}
          showView={readOnly}
          onEdit={onEdit}
          onView={onEdit}
          onDelete={onDelete}
          showDelete={!readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          rows={getRows("CERTIFICADO")}
          titles={['Nome', 'Data Documento', 'Válido Até']}
          headerTitle="Certificados"
          hasAdd={false}
          renderRows={renderRows}
          headerActions={getHeaderActions(true)}
          hasRowActions
          showEdit={!readOnly}
          showView={readOnly}
          onEdit={onEdit}
          onView={onEdit}
          onDelete={onDelete}
          showDelete={!readOnly}
        />
      </Grid>
      <DocumentoForm
        open={open}
        usuarioDocumento={usuarioDocumento}
        onClose={() => {
          setOpen(false);
          setUsuarioDocumento(null);
        }}
        isCertificado={isCertificado}
        onSave={onSaveLocal}
        documentoUsuarioIds={(values.documentos || []).map(d => d.id)}
        readOnly={readOnly}
      />
    </Grid>
  );
};

export default ArquivosTab;
