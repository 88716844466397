/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/controleFrequencia/actionsTypes';
import BaseService from '../services/BaseService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';
import fileDownload from 'js-file-download';
import FileService from '../services/FileService';
import { v4 as uuidv4 } from 'uuid';

function* loadControleFrequencias({ filtro }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'pontos/filter', data: filtro });
    yield put({
      type: TypeActions.FETCH_CONTROLE_FREQUENCIAS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* loadControleFrequencia({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: `pontos/${id}` });

    if (response.data && response.data.fotoInicioJornada && response.data.fotoInicioJornada.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.fotoInicioJornada.url);
        if (responseFile) {
          responseFile.data.name = `Inicio Jornada.${response.data.fotoInicioJornada.extensao}`;
          response.data.fotoInicioJornada = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }

    if (response.data && response.data.fotoInicioIntervalo && response.data.fotoInicioIntervalo.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.fotoInicioIntervalo.url);
        if (responseFile) {
          responseFile.data.name = `Inicio Intervalo.${response.data.fotoInicioIntervalo.extensao}`;
          response.data.fotoInicioIntervalo = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }

    if (response.data && response.data.fotoFinalIntervalo && response.data.fotoFinalIntervalo.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.fotoFinalIntervalo.url);
        if (responseFile) {
          responseFile.data.name = `Final Intervalo.${response.data.fotoFinalIntervalo.extensao}`;
          response.data.fotoFinalIntervalo = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }

    if (response.data && response.data.fotoFinalJornada && response.data.fotoFinalJornada.url) {
      try {
        const responseFile = yield FileService.getFile(response.data.fotoFinalJornada.url);
        if (responseFile) {
          responseFile.data.name = `Final Jornada.${response.data.fotoFinalJornada.extensao}`;
          response.data.fotoFinalJornada = responseFile.data;
        }
      } catch(e) {
        yield console.error(e);
      }
    }

    yield put({
      type: TypeActions.FETCH_CONTROLE_FREQUENCIA_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    const fotoInicioJornadaFile = data.fotoInicioJornada;
    const fotoInicioIntervaloFile = data.fotoInicioIntervalo;
    const fotoFinalIntervaloFile = data.fotoFinalIntervalo;
    const fotoFinalJornadaFile = data.fotoFinalJornada;
    
    let values = {
      ...data,
      fotoInicioJornada: {
        id: fotoInicioJornadaFile.id || uuidv4(),
        nome: fotoInicioJornadaFile.name.split('.')[0],
        extensao: fotoInicioJornadaFile.name.split('.').pop(),
        status: 'NOT_SYNC'
      }
    }

    if (fotoInicioIntervaloFile != null) {
      values = {
        ...values,
        fotoInicioIntervalo: {
          id: fotoInicioIntervaloFile.id || uuidv4(),
          nome: fotoInicioIntervaloFile.name.split('.')[0],
          extensao: fotoInicioIntervaloFile.name.split('.').pop(),
          status: 'NOT_SYNC'
        }
      }
    }

    if (fotoFinalIntervaloFile != null) {
      values = {
        ...values,
        fotoFinalIntervalo: {
          id: fotoFinalIntervaloFile.id || uuidv4(),
          nome: fotoFinalIntervaloFile.name.split('.')[0],
          extensao: fotoFinalIntervaloFile.name.split('.').pop(),
          status: 'NOT_SYNC'
        }
      }
    }

    if (fotoFinalJornadaFile != null) {
      values = {
        ...values,
        fotoFinalJornada: {
          id: fotoFinalJornadaFile.id || uuidv4(),
          nome: fotoFinalJornadaFile.name.split('.')[0],
          extensao: fotoFinalJornadaFile.name.split('.').pop(),
          status: 'NOT_SYNC'
        }
      }
    }
    
    const response = data.id ? yield BaseService.put({ url: `pontos/${data.id}`, data: values }) : yield BaseService.post({ url: 'pontos', data: values });

    debugger
    const validateArquivoDTO = [];
    try {
      yield FileService.updateFile(response.data.fotoInicioJornada.url, fotoInicioJornadaFile);
      validateArquivoDTO.push({id: response.data.fotoInicioJornada.id, valid: true });
    } catch(error) {
      yield console.error(error);
      validateArquivoDTO.push({id: response.data.fotoInicioJornada.id, valid: false });
    }

    if (fotoInicioIntervaloFile != null) {
      try {
        yield FileService.updateFile(response.data.fotoInicioIntervalo.url, fotoInicioIntervaloFile);
        validateArquivoDTO.push({id: response.data.fotoInicioIntervalo.id, valid: true });
      } catch(error) {
        yield console.error(error);
        validateArquivoDTO.push({id: response.data.fotoInicioIntervalo.id, valid: false });
      }
    }

    if (fotoFinalIntervaloFile != null) {
      try {
        yield FileService.updateFile(response.data.fotoFinalIntervalo.url, fotoFinalIntervaloFile);
        validateArquivoDTO.push({id: response.data.fotoFinalIntervalo.id, valid: true });
      } catch(error) {
        yield console.error(error);
        validateArquivoDTO.push({id: response.data.fotoFinalIntervalo.id, valid: false });
      }
    }

    if (fotoFinalJornadaFile != null) {
      try {
        yield FileService.updateFile(response.data.fotoFinalJornada.url, fotoFinalJornadaFile);
        validateArquivoDTO.push({id: response.data.fotoFinalJornada.id, valid: true });
      } catch(error) {
        yield console.error(error);
        validateArquivoDTO.push({id: response.data.fotoFinalJornada.id, valid: false });
      }
    }

    yield BaseService.post({ url: 'arquivos/validate', data: {arquivos: validateArquivoDTO} });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao salvar as informações!'
    });
  }
}

function* exportControleFrequencia({ filtro, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({
      url: 'pontos/export',
      method: 'POST',
      data: filtro,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Controle Frequencias.xlsx');
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Exportação realizada com sucesso!'
    });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao exportar as informações!'
    });
  }
}

function* deleteById({ id, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield BaseService.delete({ url: `pontos/${id}`});
    yield put({ type: TypeActions.CONTROLE_FREQUENCIA_DELETE_SUCCESS, id });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Informações removidas com sucesso!'
    });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao deletar!'
    });
  }
}

function* reportControleFrequencia({ filtro, callback }) {
  /*try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response =  yield BaseService.execute({
      url: `pontos/${usuarioId}/report`,
      method: 'POST',
      data: filtro,
      options: {
        responseType: 'blob'
      }
    });
    yield fileDownload(response.data, 'Relatório Controle Frequencias.xlsx');
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Relatório gerado com sucesso!'
    });
    yield callback();
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao gerar o relatório!'
    });
  }*/
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_CONTROLE_FREQUENCIAS, loadControleFrequencias);
  yield takeLatest(TypeActions.FETCH_CONTROLE_FREQUENCIA, loadControleFrequencia);
  yield takeLatest(TypeActions.SAVE_CONTROLE_FREQUENCIA, save);
  yield takeLatest(TypeActions.EXPORT_CONTROLE_FREQUENCIAS, exportControleFrequencia);
  yield takeLatest(TypeActions.REPORT_CONTROLE_FREQUENCIAS, reportControleFrequencia);
  yield takeLatest(TypeActions.CONTROLE_FREQUENCIA_DELETE, deleteById);
}